import { useCallback } from 'react'
import visitorInfo from 'visitor-info'
import device from 'current-device'
import utils from '../utils/utils'
import { setAppLanguage, getAppLanguage } from '../utils/localStorageService'
import { getBrowserName, checkStaticPage } from '../utils/contentfulHandler'
import useGeneralApi from '../apis/useGeneralApi'
import getApplication from './getApplication'
import { url as baseUrl, version } from '../config'

const isStatic = checkStaticPage()
const isCallbackRoute = utils.startsWith(decodeURIComponent(window.location.pathname), '/callback')
const query = utils.parse(window.location.search) || {}
const visitor = visitorInfo()
const osInfo = visitor.os || {}
const osMap = {
  'Mac OS': 'mac'
}

let filterParams = { filtering: true }
if (isStatic && !isCallbackRoute) {
  filterParams.platform = query.platforms || 'web'
  filterParams.browser = getBrowserName(query.browser) || ''
  filterParams.buildType = query.buildType || ''
  filterParams.minimumBuildNumber = query.minimumBuildNumber || ''
  filterParams.deviceCategory = query.deviceCategory || ''
  filterParams.operatingSystem = query.operatingSystem || ''
} else {
  const browserName = (visitor.browser || {}).name || ''
  const engineName = (visitor.engine || {}).name || ''
  filterParams.platform = 'web'
  filterParams.browser = getBrowserName(browserName, engineName) || ''
  filterParams.buildType = version.buildType || ''
  filterParams.minimumBuildNumber = version.buildNumber || ''
  filterParams.deviceCategory = device.type || ''
  if (osMap[osInfo.name]) filterParams.operatingSystem = osMap[osInfo.name] || ''
  else filterParams.operatingSystem = osInfo.name || ''
}

// Custom hook to fetch contentful data and form contentful tree
const useContentful = () => {
  const { getData } = useGeneralApi()

  const getContentful = useCallback(() => {
    const locale = getAppLanguage() || window.navigator.userLanguage || window.navigator.language || 'en-US'
    setAppLanguage(locale)

    if (locale) filterParams.locale = locale
    filterParams = utils.clearMissingValues(filterParams)
    const params = utils.stringify(filterParams)

    return getData(`${baseUrl}/entries?${params}`, {}, {}, {withCredentials: true}, { isContentfulApi: true })
      .then((res = {}) => {
        const appData = []
        const assetData = []
        const { items = [], includes = {}, appId = '', contentLocale: locale = '' } = res || {}
        const { Asset = [] } = includes || {}
        items.map((item) => appData.push(item))
        Asset.map((item) => assetData.push(item))
        setAppLanguage(locale || '')
        return Promise.resolve({ appData, assetData, appId, locale })
      })
      .then((props) => {
        const { appData = [], assetData = [], appId, locale } = props
        const asset = []
        const analyticEvent = []
        const analyticIntegration = []
        const appStoreConfiguration = []
        const application = []
        const baseConfiguration = []
        const buttonColor = []
        const buttonStyle = []
        const color = []
        const colorGradient = []
        const colorPalette = []
        const componentData = []
        const componentStyle = []
        const compositeStyle = []
        const curatedDataEntry = []
        const errorConfiguration = []
        const faqItem = []
        const featureAnalytics = []
        const featureCatalog = []
        const featurePlayer = []
        const featureRevenue = []
        const featureSearch = []
        const featureSupport = []
        const featureIntro = []
        const featureUserManagementLocal = []
        const featureProfileManagementLocal = []
        const filter = []
        const graphics = []
        const image = []
        const label = []
        const labelGroup = []
        const language = []
        const localization = []
        const maintenanceConfiguration = []
        const menuItem = []
        const navigation = []
        const page = []
        const pageComponent = []
        const playerControlConfiguration = []
        const theme = []
        const themeSection = []
        const userAuthConfiguration = []
        appData.map((item) => {
          const { sys = {} } = item
          const { contentType = {} } = sys
          const { sys: contentTypeSys = {} } = contentType
          const { id: contentTypeSysId = '' } = contentTypeSys
          if (contentTypeSysId === 'analyticEvent') {
            analyticEvent.push(item)
          }
          if (contentTypeSysId === 'analyticIntegration') {
            analyticIntegration.push(item)
          }
          if (contentTypeSysId === 'appStoreConfiguration') {
            appStoreConfiguration.push(item)
          }
          if (contentTypeSysId === 'application') {
            application.push(item)
          }
          if (contentTypeSysId === 'baseConfiguration') {
            baseConfiguration.push(item)
          }
          if (contentTypeSysId === 'buttonColor') {
            buttonColor.push(item)
          }
          if (contentTypeSysId === 'buttonStyle') {
            buttonStyle.push(item)
          }
          if (contentTypeSysId === 'color') {
            color.push(item)
          }
          if (contentTypeSysId === 'colorGradient') {
            colorGradient.push(item)
          }
          if (contentTypeSysId === 'colorPalette') {
            colorPalette.push(item)
          }
          if (contentTypeSysId === 'componentData') {
            componentData.push(item)
          }
          if (contentTypeSysId === 'componentStyle') {
            componentStyle.push(item)
          }
          if (contentTypeSysId === 'compositeStyle') {
            compositeStyle.push(item)
          }
          if (contentTypeSysId === 'curatedDataEntry') {
            curatedDataEntry.push(item)
          }
          if (contentTypeSysId === 'errorConfiguration') {
            errorConfiguration.push(item)
          }
          if (contentTypeSysId === 'faqItem') {
            faqItem.push(item)
          }
          if (contentTypeSysId === 'featureAnalytics') {
            featureAnalytics.push(item)
          }
          if (contentTypeSysId === 'featureCatalog') {
            featureCatalog.push(item)
          }
          if (contentTypeSysId === 'featurePlayer') {
            featurePlayer.push(item)
          }
          if (contentTypeSysId === 'featureRevenue') {
            featureRevenue.push(item)
          }
          if (contentTypeSysId === 'featureSearch') {
            featureSearch.push(item)
          }
          if (contentTypeSysId === 'featureSupport') {
            featureSupport.push(item)
          }
          if (contentTypeSysId === 'featureUserManagement') {
            featureUserManagementLocal.push(item)
          }
          if (contentTypeSysId === 'featureProfileManagement') {
            featureProfileManagementLocal.push(item)
          }
          if (contentTypeSysId === 'featureIntro') {
            featureIntro.push(item)
          }
          if (contentTypeSysId === 'filter') {
            filter.push(item)
          }
          if (contentTypeSysId === 'graphics') {
            graphics.push(item)
          }
          if (contentTypeSysId === 'image') {
            image.push(item)
          }
          if (contentTypeSysId === 'label') {
            label.push(item)
          }
          if (contentTypeSysId === 'labelGroup') {
            labelGroup.push(item)
          }
          if (contentTypeSysId === 'language') {
            language.push(item)
          }
          if (contentTypeSysId === 'localization') {
            localization.push(item)
          }
          if (contentTypeSysId === 'maintenanceConfiguration') {
            maintenanceConfiguration.push(item)
          }
          if (contentTypeSysId === 'menuItem') {
            menuItem.push(item)
          }
          if (contentTypeSysId === 'navigation') {
            navigation.push(item)
          }
          if (contentTypeSysId === 'page') {
            page.push(item)
          }
          if (contentTypeSysId === 'pageComponent') {
            pageComponent.push(item)
          }
          if (contentTypeSysId === 'playerControlConfiguration') {
            playerControlConfiguration.push(item)
          }
          if (contentTypeSysId === 'theme') {
            theme.push(item)
          }
          if (contentTypeSysId === 'themeSection') {
            themeSection.push(item)
          }
          if (contentTypeSysId === 'userAuthConfiguration') {
            userAuthConfiguration.push(item)
          }
          return null
        })
        assetData.map((item) => {
          const { sys = {} } = item
          const { type = '' } = sys
          if (type === 'Asset') {
            asset.push(item)
          }
          return null
        })
        const contentful = {
          asset,
          analyticEvent,
          analyticIntegration,
          appStoreConfiguration,
          application,
          baseConfiguration,
          buttonColor,
          buttonStyle,
          color,
          colorGradient,
          colorPalette,
          componentData,
          componentStyle,
          compositeStyle,
          curatedDataEntry,
          errorConfiguration,
          faqItem,
          featureAnalytics,
          featureCatalog,
          featurePlayer,
          featureRevenue,
          featureSearch,
          featureSupport,
          featureUserManagementLocal,
          featureProfileManagementLocal,
          featureIntro,
          filter,
          graphics,
          image,
          label,
          labelGroup,
          language,
          localization,
          maintenanceConfiguration,
          menuItem,
          navigation,
          page,
          pageComponent,
          playerControlConfiguration,
          theme,
          themeSection,
          userAuthConfiguration
        }
        return new Promise((resolve) => {
          const tree = getApplication(contentful)
          return resolve({
            contentfulTree: tree,
            appId,
            locale
          })
        })
      })
  }, [getData])

  return { getContentful }
}

export default useContentful
