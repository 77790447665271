import utils from './utils/utils'
import {
  contentfulConfig,
  logglyConfig as lgConfig,
  bugsnagConfig as bsConfig,
  firebaseConfig as fbConfig,
  chromecastConfig as castConfig
} from './defaults/config.default'
/* --------------------------------------------- */
/** App Name */
const app = {
  name: process.env.REACT_APP_TITLE || ''
}
export { app }
/* --------------------------------------------- */
/** App basic info */
const buildEnv = process.env.REACT_APP_BUILD_ENV || 'dev'
const buildNumber = process.env.REACT_APP_BUILD_NUMBER || 0
const buildVersion = process.env.REACT_APP_BUILD_VERSION || '1.0.0'
const project = process.env.REACT_APP_BUILD_PROJECT || ''
const version = {
  name: '',
  number: buildVersion,
  buildType: buildEnv,
  buildNumber,
  env: buildEnv,
  project,
  appVersion: ''
}
const env = (version.env === 'staging') ? 'uat' : version.env // temporary line
if (version.env === 'prod') {
  version.name = version.number
} else {
  version.name = `${version.number}-${env.toUpperCase()}(${version.buildNumber})`
}
version.appVersion = `${version.number}-${env.toUpperCase()}`
const appName = app.name || ''
export { version, appName }
/* --------------------------------------------- */
/** Platform info */
const query = utils.parse(window.location.search)
const platform = (query.platforms || 'web').toLowerCase()
export { platform }
/* --------------------------------------------- */
/** Contentful config */
const configContentful = contentfulConfig[version.env]
const url = configContentful.url
export { url }
/* --------------------------------------------- */
/** Firebase config */
const firebaseConfig = fbConfig[version.env]
export { firebaseConfig }
/* --------------------------------------------- */
/** To identify if env is localHost */
const isLocalHost = (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === ''
)
export { isLocalHost }
/* --------------------------------------------- */
/** Bugsnag Config */
let releaseStage = ''
if (isLocalHost) {
  releaseStage = 'localhost'
} else if (version.buildType === 'Prod' || version.buildType === 'preprod') {
  releaseStage = 'production'
} else {
  releaseStage = 'development'
}
const bugsnagConfig = {
  apiKey: bsConfig[version.env].id,
  appVersion: version.name,
  notifyReleaseStages: ['development', 'production'],
  releaseStage: releaseStage,
  enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user']
}
export { bugsnagConfig }
/* --------------------------------------------- */
/** Loggly Config */
const logglyConfig = {
  id: lgConfig[version.env].id
}
export { logglyConfig }
/* --------------------------------------------- */
/** Chromecast Config */
const chromecastConfig = {
  id: castConfig[version.env].id
}
export { chromecastConfig }
/* --------------------------------------------- */
