import { useEffect, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  isTablet,
  isMobile
} from 'react-device-detect'
/* ---------------------------------------------------------------------------------------------------- */
import utils from '../utils/utils'
import useErrorUtils from '../utils/useErrorUtils'
import { getUserAuthInfo, splitLanguage, getAutoPlayNextEpisode, setAutoPlayNextEpisode } from '../utils/localStorageService'
import { setRecentlyWatchedLocal, setFavouritesLocal, setRecentSearchesLocal, getLandingPageRendered } from '../utils/sessionStorageService'
/* ---------------------------------------------------------------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* ---------------------------------------------------------------------------------------------------- */
import useGeneralApi from '../apis/useGeneralApi'
import useAuthApi from '../apis/useAuthApi'
import useDetailsApi from '../apis/useDetailsApi'
import useFavApi from '../apis/useFavApi'
/* ---------------------------------------------------------------------------------------------------- */
import useErrorHandler from '../containers/useErrorHandler'
import useLoggly from '../containers/useLoggly'
import useLogout from '../containers/useLogout'
import { version } from '../config'
import { routeMap } from '../route'
/* ---------------------------------------------------------------------------------------------------- */
// Container component
const AppBoundry = (props) => {
  const {
    appConfig,
    maintenanceConfig
  } = useSelector(store => store.appConfigReducers)
  const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { searchConfig } = useSelector(store => store.search)
  /* ------------------------------------------------------------------------------------------------ */
  const { getSessionState } = useGeneralApi()
  const { getRecentSearch, getRecentlyWatched } = useDetailsApi()
  const { refreshTrustedLogin } = useAuthApi()
  const { getFavourites } = useFavApi()
  const logout = useLogout()
  const isLandingPageRendered = getLandingPageRendered()
  const {
    appLanguage,
    isAppLoaded,
    isAuthenticated,
    dispatch: globalDispatch,
    isForceLogout,
    selectedProfile,
    refreshCallState,
    isShowSplash
  } = useContext(GlobalContext)
  /* ------------------------------------------------------------------------------------------------ */
  const location = useLocation()
  /* ------------------------------------------------------------------------------------------------ */
  const { showError, hideError } = useErrorUtils()
  const { log } = useLoggly()
  const { handleError } = useErrorHandler()
  /* ------------------------------------------------------------------------------------------------ */
  const locationSearchRef = useRef(location.search)
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    const autoPlay = getAutoPlayNextEpisode()
    if (autoPlay === 'true' || autoPlay === '') {
      globalDispatch({ type: 'AUTOPLAY_TRUE' })
      setAutoPlayNextEpisode(true)
    } else {
      globalDispatch({ type: 'AUTOPLAY_FALSE' })
      setAutoPlayNextEpisode(false)
    }
  }, [globalDispatch])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once when user visited the site. 
  // And this get executed only if the user already logged in before.
  useEffect(() => {
    if (!isAppLoaded) return
    if (refreshCallState === 'INITIATED') return
    if (refreshCallState === 'ENDED') return

    const sessionState = getSessionState()
    const authInfo = getUserAuthInfo()
    // const selectedProfileLocal = JSON.parse(window.store.localStorage.getItem('selected-profile')) || null
    const identityProvider = authInfo.identityProvider
    if (sessionState === 'INVALID_SESSION' || !identityProvider || identityProvider === 'mpx') {
      if (sessionState === 'VALID_SESSION' && identityProvider === 'mpx') {
        globalDispatch({ type: 'USER_INFO', value: authInfo || { profileType: 'adult' } })
        globalDispatch({ type: 'USER_TOKEN', value: authInfo.token || '' })
        globalDispatch({ type: 'AUTHENTICATED' })
        // globalDispatch({ type: 'SELECTED_PROFILE', value: selectedProfileLocal })
        const selectedProfilefromLS = JSON.parse(window.store.localStorage.getItem('selected-profile'))
        globalDispatch({ type: 'SELECTED_PROFILE', value: selectedProfilefromLS })
      }
      globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
      globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })

      if (window.appLaunchTrace) {
        window.appLaunchTrace.addAttrs({ isLoggedIn: 'false' })
        window.appLaunchTrace.stop()
      }
      return
    }

    if (window.appLaunchTrace) window.appLaunchTrace.addAttrs({ isLoggedIn: 'true' })

    globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: false })
    globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'INITIATED' })
    refreshTrustedLogin(true)
      .then((userInfo) => {
        userInfo = userInfo || {}
        log({ type: 'Debug', attr: { event: '_user.login' } })
        globalDispatch({ type: 'IS_APP_LOADED', value: false })
        globalDispatch({ type: 'AUTHENTICATED' })
        globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
        globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
        globalDispatch({ type: "USER_NAVIGATION_FROM_LOGIN", value: true })
        if (window.appLaunchTrace) {
          window.appLaunchTrace.addAttrs({
            isLoggedIn: 'true',
            userId: userInfo.userId || ''
          })
          window.appLaunchTrace.stop()
        }
      })
      .catch((error = {}) => {
        if (error.errorCode !== 7001) handleError(error)
        globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
        globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
        if (window.appLaunchTrace) window.appLaunchTrace.stop()
      })
  }, [
    handleError,
    isAppLoaded,
    getSessionState,
    globalDispatch,
    log,
    refreshCallState,
    refreshTrustedLogin,
    selectedProfile
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once refreshTrusted logi n gets complete
  // And recentlyWatched data fetched and stored in sessionstorage for later use.
  useEffect(() => {
    if (!isAppLoaded) return
    if (!isShowSplash) return
    if (refreshCallState !== 'ENDED') return
    if (version.project !== 'videotron') {
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
      globalDispatch({ type: 'SHOW_SPLASH', value: false })
      return
    }
    let isMounted = true
    if (isAuthenticated) {
      if (!appConfig.baseUrl) return
      let params = {
        page: 1,
        language: splitLanguage(appLanguage),
        size: 20
      }
      params = utils.stringify(params)
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: false })
      getRecentlyWatched(appConfig.baseUrl, params)
        .then((res) => {
          if (!isMounted) return null
          const content = (res.content || [])
          setRecentlyWatchedLocal(content)
          globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
          globalDispatch({ type: 'SHOW_SPLASH', value: false })
        }).catch(() => {
          if (!isMounted) return null
          globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
          globalDispatch({ type: 'SHOW_SPLASH', value: false })
        })
    } else {
      setRecentlyWatchedLocal([])
      if(location.pathname !== routeMap.gettingStarted || (location.pathname !== '/' && isLandingPageRendered))
      globalDispatch({ type: 'SHOW_SPLASH', value: false })
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
    }

    return () => {
      isMounted = false
    }
  }, [
    appLanguage,
    isAuthenticated,
    isAppLoaded,
    location,
    getRecentlyWatched,
    isShowSplash,
    appConfig.baseUrl,
    globalDispatch,
    isLandingPageRendered,
    refreshCallState
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once recentlyWatched api is complete
  // And recentSearch data fetched and stored in sessionstorage for later use.
  useEffect(() => {
    if (!isAppLoaded) return
    if (!appConfig.baseUrl) return
    if (utils.isNull(searchConfig.maxSearchHistoryCount)) return
    if (isShowSplash) return

    let isMounted = true
    if (isAuthenticated) {
      const profileId = selectedProfile ? selectedProfile.profileId : ''
      getRecentSearch(appConfig.baseUrl, searchConfig.maxSearchHistoryCount, profileId)
        .then((res) => {
          if (!isMounted) return null
          let { searchList } = res
          searchList = [...new Set(searchList)]
          searchList = searchList.slice(0, searchConfig.maxSearchHistoryCount)
          setRecentSearchesLocal(searchList)
        }).catch(() => { })
    } else {
      setRecentSearchesLocal([])
    }

    return () => {
      isMounted = false
    }
  }, [
    isAuthenticated,
    isAppLoaded,
    getRecentSearch,
    isShowSplash,
    appConfig.baseUrl,
    searchConfig.maxSearchHistoryCount,
    selectedProfile
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once recentlyWatched api is complete
  // And favourites data fetched and stored in sessionstorage for later use.
  useEffect(() => {
    if (!isAppLoaded) return
    if (!appConfig.baseUrl) return
    if (isShowSplash) return
    if (version.project !== 'videotron') return
    if (isAuthenticated) {
      const options = {
        language: splitLanguage(appLanguage),
        size: 50,
        page: 1
      }
      globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'IDLE' })
      getFavourites(appConfig.baseUrl, options)
        .then((res) => {
          setFavouritesLocal(res.content || [])
          globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
        })
        .catch(() => {
          globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
        })
    } else {
      setFavouritesLocal([])
    }
  }, [
    appLanguage,
    isAuthenticated,
    isAppLoaded,
    getFavourites,
    isShowSplash,
    appConfig.baseUrl,
    globalDispatch
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to differentiate moble user from non mobile user.
  useEffect(() => {
    if (!isAppLoaded) return
    if ((playerFeature && !playerFeature.allowMobileBrowserPlayback) && (isMobile || isTablet)) {
      globalDispatch({ type: 'MOBILE_USER' })
    } else globalDispatch({ type: 'NONMOBILE_USER' })
  }, [
    isAppLoaded, playerFeature.allowMobileBrowserPlayback, globalDispatch, playerFeature
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to identify shared url.
  useEffect(() => {
    const queryStringObj = utils.parse(locationSearchRef.current)
    const isShared = Boolean(queryStringObj.shared)
    globalDispatch({ type: 'IS_SHARED', value: isShared })
  }, [globalDispatch])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to show login popup to the user.
  useEffect(() => {
    if (!isAppLoaded) return

    const queryStringObj = utils.parse(locationSearchRef.current)
    const isShared = Boolean(queryStringObj.shared)
    const sessionState = getSessionState()
    if (
      (
        sessionState === 'INVALID_SESSION' ||
        sessionState === 'SESSION_EXPIRED'
      ) &&
      userManagementConfig.showLoginPopup === 'OnSplash' &&
      !isShared
    ) {
      globalDispatch({ type: 'SHOW_LOGIN_POPUP' })
    }
  }, [
    isAppLoaded,
    globalDispatch,
    userManagementConfig.showLoginPopup,
    getSessionState
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to show App maintenance error based on contentful config.
  useEffect(() => {
    if (maintenanceConfig.showMaintenanceMessage) {
      showError({ type: 'AppMaintenance' })
    }
  }, [showError, maintenanceConfig.showMaintenanceMessage])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to logout a user.
  useEffect(() => {
    if (isForceLogout) {
      logout()
    }
  }, [isForceLogout])// eslint-disable-line react-hooks/exhaustive-deps
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to show/hide network error based on online/offline status from browser.
  useEffect(() => {
    let timer = null

    const isOnline = () => {
      clearTimeout(timer)
      if (!isAppLoaded) window.location.reload()
      hideError({ type: 'AppNoNetwork' })
    }

    const isOffline = () => {
      timer = setTimeout(() => {
        showError({ type: 'AppNoNetwork' })
      }, 3000)
    }

    window.addEventListener('online', isOnline)
    window.addEventListener('offline', isOffline)

    return () => {
      window.removeEventListener('online', isOnline)
      window.removeEventListener('offline', isOffline)
      clearTimeout(timer)
    }
  }, [hideError, isAppLoaded, showError])
  /* ------------------------------------------------------------------------------------------------ */
  return null
}
/* ---------------------------------------------------------------------------------------------------- */
export default AppBoundry
